import { darken } from 'polished'
import styled from 'styled-components'

export const PreviewSocialsBox = styled.ul`
  position: absolute;
  left: 50%;
  bottom: 10vh;
  width: 100%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  z-index: 3;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    width: calc(100% - 20px);
    max-width: 380px;
    bottom: 3vh;
  }

  @media screen and (max-width: 768px) and (max-height: 720px) {
    bottom: 10px;
  }

  @media screen and (max-height: 720px) {
    bottom: 10px;
  }
`

export const PreviewSocial = styled.li`
  @media screen and (max-width: 980px) {
    width: 100%;
  }
`

export const SocialLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  padding: 16px 24px;
  border-radius: 16px;

  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  background-color: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.appTextColor};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.primaryColor)};
  }
`

export const SocialIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: auto;
`
