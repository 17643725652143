import { createGlobalStyle } from 'styled-components'

export const lightTheme = {
  appColor: '#FFFFFF',
  appBg: '#5E54FF',
  appTextColor: '#FFFFFF',
  primaryColor: '#5E54FF',
}

export const darkTheme = {}

export const ThemedGlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  color: ${({ theme }) => theme.appTextColor};
  background: ${({ theme }) => theme.appBg};

  font-family: 'General Sans', 'Arial', sans-serif;
  font-size: 15px;
  font-weight: 500;

  height: fill-available;
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
}

#root {
  min-height: 100vh;
}

button {
  user-select: none;
}

button, a {
  transition: opacity 0.3s;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  
  &:active {
    opacity: 0.4;
  }
}

a {
  font: inherit;
  text-decoration: none;
}
`
