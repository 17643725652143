import { useDarkMode } from 'hooks/useDarkMode'
import { lightTheme, darkTheme } from 'theme'
import { ThemeProvider } from 'styled-components'
import { ThemedGlobalStyle } from 'theme'
import {
  AppBg,
  AppBox,
  AppContent,
  AppHeading,
  AppLabel,
  AppMain,
  AppSlogan,
  BgMainBox,
  BgMainImge,
  BgMainShadow,
  BgSubShadow,
  CloudLeftImage,
  CloudRightImage,
  MobileIllustrationOne,
  MobileIllustrationTwo,
} from './styles'
import MainBg from 'assets/images/cometa.png'
import MainBg2x from 'assets/images/cometa2x.png'
import MainBgMob from 'assets/images/cometa_mobile.png'
import MainBgMob2x from 'assets/images/cometa_mobile_2x.png'
import CloudLeftBg from 'assets/images/cloud-left.png'
import CloudRightBg from 'assets/images/cloud-right.png'
import Blue from 'assets/images/blue.png'
import Orange from 'assets/images/orange.png'

import '../index.css'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { PreviewHeader } from 'components/PreviewHeader'
import { PreviewSocials } from 'components/PreviewSocials'
import { useMedia } from 'react-use'

function App() {
  const [loading, setLoading] = useState(true)
  const [theme] = useDarkMode()
  const themeMode = theme === 'light' ? lightTheme : darkTheme
  const isMobile = useMedia('(max-width: 768px)')

  return (
    <ThemeProvider theme={themeMode}>
      <ThemedGlobalStyle />

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 3 }}>
        <AppBox>
          <PreviewHeader />
          <AppMain>
            <AppContent>
              <AppHeading>Arcana</AppHeading>
              <AppLabel
                as={motion.h2}
                animate={{ opacity: [0, 1] }}
                transition={{
                  duration: 3,
                  delay: 3.3,
                  ease: [0.19, 1, 0.22, 1],
                }}
              >
                Beta launch Q4 2022
              </AppLabel>
              <AppSlogan
                as={motion.h3}
                animate={{ opacity: [0, 1], y: isMobile ? [20, 0] : [100, 0] }}
                transition={{
                  duration: 3.5,
                  ease: [0.2, 0.8, 0.2, 1],
                  delay: 2.5,
                }}
              >
                The future
                <br /> of yield leverage
              </AppSlogan>
            </AppContent>

            <PreviewSocials />
          </AppMain>

          <AppBg>
            {isMobile ? (
              <>
                <MobileIllustrationOne
                  src={Orange}
                  as={motion.img}
                  animate={{ opacity: [0, 1] }}
                  transition={{ duration: 2, delay: 2.5, ease: [0.19, 1, 0.22, 1] }}
                />
                <MobileIllustrationTwo
                  src={Blue}
                  as={motion.img}
                  animate={{ opacity: [0, 1] }}
                  transition={{ duration: 2, delay: 2.5, ease: [0.19, 1, 0.22, 1] }}
                />
              </>
            ) : (
              <>
                <BgMainShadow></BgMainShadow>
                <BgSubShadow
                  as={motion.div}
                  animate={{ opacity: [0, 1] }}
                  transition={{ duration: 4, delay: 0, ease: [0.19, 1, 0.22, 1] }}
                ></BgSubShadow>
              </>
            )}

            <BgMainBox
              animate={{ opacity: [0, 1] }}
              transition={{ duration: 1.6, delay: 1.8, ease: [0.19, 1, 0.22, 1] }}
              as={motion.div}
            >
              <BgMainImge
                as={motion.img}
                animate={{ rotateZ: [0, 90, 0] }}
                transition={{ repeat: Infinity, duration: 30, ease: ['easeInOut', 'easeInOut'], delay: 2 }}
                src={isMobile ? MainBgMob : MainBg}
                srcSet={`${isMobile ? MainBgMob2x : MainBg2x} 1.5x`}
                alt="Cometa"
                onLoad={() => setLoading(false)}
                style={{ display: !loading ? 'block' : 'none' }}
              />
            </BgMainBox>

            {!isMobile && (
              <>
                <CloudLeftImage
                  as={motion.img}
                  animate={{ x: [-100, 0], opacity: [0, 1] }}
                  transition={{ duration: 4, delay: 2.5, ease: [0.19, 1, 0.22, 1] }}
                  src={CloudLeftBg}
                  alt="Cloud left"
                />
                <CloudRightImage
                  as={motion.img}
                  animate={{ x: [100, 0], opacity: [0, 1] }}
                  transition={{ duration: 4, delay: 2.5, ease: [0.19, 1, 0.22, 1] }}
                  src={CloudRightBg}
                  alt="Cloud right"
                />
              </>
            )}
          </AppBg>
        </AppBox>
      </motion.div>
    </ThemeProvider>
  )
}

export default App
