import { motion } from 'framer-motion'
import {
  PreviewHeaderBox,
  PreviewHeaderLogo,
  PreviewHeaderLink,
  PreviewHeaderLinkLogo,
  PreviewHeaderLinkText,
} from './styles'
import PreviewLogo from 'assets/logos/arcana.svg'
import BscLogo from 'assets/logos/bsc.svg'
import { useMedia } from 'react-use'

export const PreviewHeader: React.FC = () => {
  const isMobile = useMedia('(max-width: 768px)')

  return (
    <PreviewHeaderBox>
      <PreviewHeaderLogo
        as={motion.img}
        animate={{ x: isMobile ? [20, 0] : [100, 0], opacity: [0, 1] }}
        transition={{
          duration: 3,
          ease: [0.19, 1, 0.22, 1],
          delay: 2.5,
        }}
        src={PreviewLogo}
        alt="Arcana"
        width="173px"
        height="24px"
      />

      <PreviewHeaderLink
        href="https://www.bnbchain.org/"
        target="_blanl"
        rel="noreferrer"
        as={motion.a}
        animate={{ x: isMobile ? [-20, 0] : [-100, 0], opacity: [0, 1] }}
        transition={{
          duration: 3,
          ease: [0.19, 1, 0.22, 1],
          delay: 2.5,
        }}
      >
        <PreviewHeaderLinkLogo src={BscLogo} alt="Binance Smart Chain" />
        <PreviewHeaderLinkText>Binance Smart Chain</PreviewHeaderLinkText>
      </PreviewHeaderLink>
    </PreviewHeaderBox>
  )
}
