import styled from 'styled-components'

export const AppBox = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 44px 0;
  overflow: hidden;

  @media screen and (max-height: 720px) {
    padding: 20px 0;
  }

  @media screen and (max-width: 980px) {
    padding: 20px 0;
  }
`

export const AppMain = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  width: 100%;
  height: calc(100vh - 135px);

  @media screen and (max-height: 720px) {
    margin-top: 15px;
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    height: calc(100vh - 220px);
  }

  @media screen and (max-width: 768px) and (max-height: 740px) {
    margin-top: 60px;
    height: calc(100vh - 150px);
  }
`

export const AppContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    margin-top: -40vh;
    gap: 20px;
  }
`

export const AppHeading = styled.h1`
  display: none;
`

export const AppLabel = styled.h2`
  position: relative;
  z-index: 5;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  @media screen and (max-width: 980px) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const AppSlogan = styled.h2`
  font-family: 'Cabinet Grotesk', sans-serif;
  font-weight: 800;
  font-size: 148px;
  line-height: 140px;

  @media screen and (max-height: 800px) {
    font-size: 120px;
    line-height: 110px;
  }

  @media screen and (max-width: 1420px) {
    font-size: 128px;
    line-height: 120px;
  }

  @media screen and (max-width: 1210px) {
    font-size: 100px;
    line-height: 110px;
  }

  @media screen and (max-width: 980px) {
    font-size: 68px;
    line-height: 64px;
    padding: 0 5px;
  }

  @media screen and (max-width: 370px) {
    font-size: 54px;
    line-height: 54px;
  }
`

export const AppBg = styled.div`
  position: absolute;
  bottom: -4vh;
  left: 0;
  width: 100%;
  height: 100%;

  @media screen and (max-height: 750px) {
    bottom: -6vh;
  }

  @media screen and (max-height: 720px) {
    bottom: -10vh;
  }
  @media screen and (max-height: 640px) {
    bottom: -22vh;
  }
  @media screen and (max-width: 980px) {
    bottom: 17vh;
  }
  @media screen and (max-width: 768px) {
    bottom: 16vh;
  }
  @media screen and (max-width: 768px) and (max-height: 720px) {
    bottom: 10vh;
  }
`

export const BgMainBox = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 1563px;
  height: 1563px;
  transform: translateX(-50%);
  z-index: 2;

  @media screen and (max-width: 768px) {
    width: 1066px;
    height: 1066px;
  }
`

export const BgMainImge = styled.img`
  position: absolute;
  left: 0;
  bottom: -1030px;
  width: 1563px;
  height: 1563px;

  @media screen and (max-width: 768px) {
    width: 1066px;
    height: 1066px;
    bottom: -722px;
  }
`

export const BgMainShadow = styled.div`
  position: absolute;
  left: 50%;
  bottom: -1175px;
  width: 1870px;
  height: 1870px;
  background: radial-gradient(50% 50% at 50% 50%, #fecace 22.92%, rgba(254, 202, 206, 0) 100%);
  filter: blur(80px);
  transform: translateX(-50%);
  border-radius: 0;
  z-index: -1;

  @media screen and (max-width: 768px) {
    bottom: auto;
    top: 100px;
  }
`

export const BgSubShadow = styled.div`
  position: absolute;
  left: 50%;
  bottom: -771px;
  width: 1352px;
  height: 1127px;
  transform: translateX(-50%);
  background: radial-gradient(50% 50% at 50% 50%, #5e54ff 81.25%, rgba(94, 84, 255, 0) 100%);
  opacity: 0.6;
  filter: blur(80px);
  border-radius: 50%;
  z-index: -1;

  @media screen and (max-width: 768px) {
    bottom: 120px;
    opacity: 0.3;
  }
`

export const MobileIllustrationOne = styled.img`
  position: absolute;
  left: 50%;
  bottom: -1175px;
  width: 1870px;
  height: 1870px;
  transform: translateX(-50%);
  z-index: -1;

  @media screen and (max-width: 768px) {
    bottom: auto;
    top: 200px;
    z-index: -2;
  }
`

export const MobileIllustrationTwo = styled.img`
  position: absolute;
  left: 50%;
  bottom: -771px;
  width: 1352px;
  height: 1127px;
  transform: translateX(-50%);
  opacity: 0.6;
  z-index: -1;

  @media screen and (max-width: 768px) {
    top: calc(100% - 250px);
    bottom: auto;
    opacity: 0.3;
    z-index: -1;
  }
`

export const CloudLeftImage = styled.img`
  position: absolute;
  left: -333px;
  bottom: -527px;
  width: 1152px;
  height: 1152px;
  z-index: -2;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const CloudRightImage = styled.img`
  position: absolute;
  right: -560px;
  bottom: -425px;
  width: 1068px;
  height: 1152px;
  z-index: -2;

  @media screen and (max-width: 768px) {
    display: none;
  }
`
