import React from 'react'

export const TwitterIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.953 2.32351C23.0545 2.71911 22.1026 2.98024 21.128 3.09851C22.1541 2.48176 22.9224 1.51455 23.291 0.375505C22.34 0.930505 21.286 1.33451 20.164 1.55951C19.4238 0.767935 18.4429 0.242926 17.3736 0.0660066C16.3044 -0.110913 15.2067 0.0701556 14.2509 0.581096C13.2952 1.09204 12.5349 1.90426 12.0881 2.89163C11.6412 3.879 11.533 4.98627 11.78 6.04151C7.69 5.84851 4.067 3.8835 1.64 0.915505C1.19879 1.66532 0.968664 2.52053 0.974 3.39051C0.974 5.10051 1.844 6.60351 3.162 7.48651C2.38086 7.46164 1.61697 7.25044 0.934 6.8705V6.93051C0.933557 8.0669 1.32627 9.16844 2.04551 10.0483C2.76474 10.9281 3.76621 11.532 4.88 11.7575C4.1583 11.9509 3.40241 11.9799 2.668 11.8425C2.98412 12.8205 3.59766 13.6754 4.42301 14.288C5.24837 14.9006 6.24435 15.2402 7.272 15.2595C5.53153 16.6255 3.38248 17.3668 1.17 17.3645C0.78 17.3645 0.391 17.3415 0 17.2975C2.25571 18.7418 4.87851 19.5085 7.557 19.5065C16.61 19.5065 21.555 12.0105 21.555 5.52151C21.555 5.31151 21.555 5.10151 21.54 4.89151C22.506 4.19637 23.3392 3.33332 24 2.34351L23.953 2.32351Z"
        fill="currentColor"
      />
    </svg>
  )
}
