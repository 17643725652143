import React from 'react'

export const TelegramIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.7061 0.263943C20.3461 -0.0413369 19.7807 -0.0850169 19.1961 0.149703H19.1951C18.5802 0.396423 1.78983 7.59834 1.10631 7.89258C0.981988 7.93578 -0.103772 8.3409 0.00806826 9.2433C0.107908 10.0569 0.980548 10.3939 1.08711 10.4327L5.35575 11.8943C5.63895 12.8371 6.68295 16.3151 6.91383 17.0582C7.05783 17.5214 7.29255 18.13 7.70391 18.2553C8.06487 18.3945 8.42391 18.2673 8.65623 18.0849L11.266 15.6643L15.4789 18.9499L15.5793 19.0099C15.8653 19.1366 16.1394 19.1999 16.401 19.1999C16.6031 19.1999 16.797 19.162 16.9823 19.0862C17.6135 18.827 17.866 18.2255 17.8924 18.1574L21.0393 1.80042C21.2313 0.926823 20.9644 0.482343 20.7061 0.263943ZM9.12039 12.479L7.68039 16.319L6.24039 11.519L17.2804 3.35898L9.12039 12.479Z"
        fill="currentColor"
      />
    </svg>
  )
}
