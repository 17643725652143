import { motion } from 'framer-motion'
import { DiscordIcon } from 'icons/DiscordIcon'
import { TelegramIcon } from 'icons/TelegramIcon'
import { TwitterIcon } from 'icons/TwitterIcon'
import { useMedia } from 'react-use'
import { PreviewSocialsBox, PreviewSocial, SocialLink, SocialIcon } from './styles'

export const PreviewSocials: React.FC = () => {
  return (
    <PreviewSocialsBox>
      <PreviewSocial>
        <SocialLink
          href="https://twitter.com/Arcana_fi"
          target="_blank"
          rel="noreferrer"
          as={motion.a}
          animate={{ opacity: [0, 1], y: [20, 0] }}
          transition={{
            duration: 3,
            ease: [0.19, 1, 0.22, 1],
            delay: 2.7,
          }}
        >
          <SocialIcon>
            <TwitterIcon />
          </SocialIcon>
          Follow us on Twitter
        </SocialLink>
      </PreviewSocial>
      <PreviewSocial>
        <SocialLink
          href="https://discord.gg/fxDutRbH5C"
          target="_blank"
          rel="noreferrer"
          as={motion.a}
          animate={{ opacity: [0, 1], y: [30, 0] }}
          transition={{
            duration: 3,
            ease: [0.19, 1, 0.22, 1],
            delay: 2.5,
          }}
        >
          <SocialIcon>
            <DiscordIcon />
          </SocialIcon>
          Follow us on Discord
        </SocialLink>
      </PreviewSocial>
      <PreviewSocial>
        <SocialLink
          href="https://t.me/arcana_fi"
          target="_blank"
          rel="noreferrer"
          as={motion.a}
          animate={{ opacity: [0, 1], y: [20, 0] }}
          transition={{
            duration: 3,
            ease: [0.19, 1, 0.22, 1],
            delay: 2.7,
          }}
        >
          <SocialIcon>
            <TelegramIcon />
          </SocialIcon>
          Follow us on Telegram
        </SocialLink>
      </PreviewSocial>
    </PreviewSocialsBox>
  )
}
