import styled from 'styled-components'
import { rgba } from 'polished'

export const PreviewHeaderBox = styled.header`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 0 80px;
  z-index: 3;

  @media screen and (max-width: 980px) {
    padding: 0 20px;
    gap: 20px;
  }
`

export const PreviewHeaderLogo = styled.img`
  width: 100%;
  max-width: 173px;

  @media screen and (max-width: 768px) {
    max-width: 116px;
  }
`

export const PreviewHeaderLink = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 16px 24px;
  border-radius: 16px;

  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  background-color: ${({ theme }) => theme.appColor};
  color: ${({ theme }) => theme.primaryColor};
  transition: background-color 0.3s;

  @media screen and (max-width: 768px) {
    border-radius: 50%;
    padding: 16px 17px;
  }

  &:hover {
    background-color: ${({ theme }) => rgba(theme.appColor, 0.8)};
  }
`

export const PreviewHeaderLinkLogo = styled.img`
  width: 17px;
  height: 20px;
`

export const PreviewHeaderLinkText = styled.span`
  @media screen and (max-width: 768px) {
    display: none;
  }
`
